import cover_img_1 from "../images/apple-and-books.jpeg";
import cover_img_2 from "../images/maya-maceka-yW-Qgw_IJXg-unsplash.jpg";
import cover_img_3 from "../images/patrick-tomasso-Oaqk7qqNh_c-unsplash.jpg";
import cover_img_4 from "../images/gaelle-marcel-L8SNwGUNqbU-unsplash.jpg";
import cover_img_5 from "../images/wulan-sari-mHjvJqvj1XE-unsplash.jpg";
import cover_img_6 from "../images/syd-wachs-slItfWbhijc-unsplash.jpg";
import cover_img_7 from "../images/gaelle-marcel-L8SNwGUNqbU-unsplash.jpg";

export const Slides = [
    {
        title: "Home",
        cover_img: cover_img_1,
        description: "Welcome to Grand Canyon Tutoring Site, your one-stop destination for all your tutoring and assignment help needs.",
        button: {
            id: "Get Started",
            link: "/#get-started",
        }
    },
    {
        title: "About Us",
        cover_img: cover_img_2,
        description: "We provide exceptional tutoring services specifically tailored for Grand Canyon University students.",
        button: {
            id: "Place Order",
            link: "/#place-order",
        }
    },
    {
        title: "Services",
        cover_img: cover_img_3,
        description: "We provide a wide range of services, including assignment help, tutoring sessions, and project assistance.",
        button: {
            id: "Place Order",
            link: "/#place-order",
        }
    },
    {
        title: "Papers",
        cover_img: cover_img_4,
        description: "Stay updated with the latest sample academic papers, tips, and resources related to your study program.",
        button: {
            id: "Browse",
            link: "/papers",
        }
    },
    {
        title: "Testimonials",
        cover_img: cover_img_5,
        description: "Read what our satisfied students have to say about their experiences with our tutoring services.",
        button: {
            id: "View",
            link: "/#testimonials",
        }
    },
    {
        title: "FAQs",
        cover_img: cover_img_6,
        description: "Find answers to frequently asked questions about our services, processes, and policies.",
        button: {
            id: "Browse FAQs",
            link: "/faqs",
        }
    },
    {
        title: "Contact Us",
        cover_img: cover_img_7,
        description: "Get in touch with us for any inquiries, support, or feedback. We are here to assist you.",
        button: {
            id: "Contact Us",
            link: "/#sample-papers",
        },
    },
];

